document.addEventListener("turbolinks:load", () => {
  const mediumSelect = document.getElementById("medium_file_type");

  if (mediumSelect) {
    mediumSelect.addEventListener("change", () => {
      const mediumValue = mediumSelect.value;
      const videoChapters = document.getElementById("video-chapters");

      if (mediumValue === "video") {
        videoChapters.style.display = "block";
      } else {
        videoChapters.style.display = "none";
      }
    });
  }
});
