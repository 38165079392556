document.addEventListener("turbolinks:load", () => {
  const allowsProgressPaymentsCheckbox = document.getElementById(
    "brand_allows_progress_payments"
  );
  const maxProgressPaymentsInput = document.getElementById(
    "brand_max_progress_payments"
  );

  if (allowsProgressPaymentsCheckbox) {
    allowsProgressPaymentsCheckbox.addEventListener("change", () => {
      if (allowsProgressPaymentsCheckbox.checked) {
        maxProgressPaymentsInput.value = 3;
      } else {
        maxProgressPaymentsInput.value = 0;
      }
    });
  }

  if (maxProgressPaymentsInput) {
    maxProgressPaymentsInput.addEventListener("change", () => {
      if (maxProgressPaymentsInput.value > 0) {
        allowsProgressPaymentsCheckbox.checked = true;
      } else {
        allowsProgressPaymentsCheckbox.checked = false;
      }
    });
  }
});
