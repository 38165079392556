const debounce = (callback, wait) => {
  let timeoutId = null;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
}

const search = debounce((e) => {
  const searchInput = document.getElementById('media-search-input');
  const { url } = searchInput.dataset;
  const searchTerm = searchInput.value;

  let searchUrl = new URL(url);
  const params = { search: searchTerm };
  Object.keys(params).forEach(key => searchUrl.searchParams.append(key, params[key]))
  $.get(searchUrl);
}, 500);


const searchListener = () => {
  const searchButton = document.getElementById('media-search-input');
  if (!searchButton) return;
  searchButton.addEventListener("keydown", search);
}

document.addEventListener("turbolinks:load", searchListener);

