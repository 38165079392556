import Trix from "trix"

Trix.config.textAttributes.underline = { tagName: "u", inheritable: false};

document.addEventListener("trix-initialize", function(event) {
  var element = event.target
  var editor = element.editor
  var toolbarElement = element.toolbarElement
  var groupElement = toolbarElement.querySelector(".trix-button-group.trix-button-group--text-tools")
  var btnHTML = `<button type="button" class="trix-underline trix-button trix-button--icon" data-trix-attribute="underline" title="underline"></button>`;

  groupElement.insertAdjacentHTML("beforeend", btnHTML)

  var selectedAttributes = new Set
  function updateSelectedAttributes() {
    selectedAttributes = new Set

    var selectedRange = editor.getSelectedRange()
    if (selectedRange[0] === selectedRange[1]) { selectedRange[1]++ }

    var selectedPieces = editor.getDocument().getDocumentAtRange(selectedRange).getPieces()
    selectedPieces.forEach(function(piece) {
      Object.keys(piece.getAttributes()).forEach(function(attribute) {
        selectedAttributes.add(attribute)
      })
    })
  }


  updateSelectedAttributes()
  element.addEventListener("trix-selection-change", updateSelectedAttributes)
  element.addEventListener("trix-change", enforceExclusiveAttributes)

  if(element.dataset.content){
    editor.insertHTML(element.dataset.content)
  }
})
