$(document).on("turbolinks:load", function () {
  const brandSelector = $("#brand_select");
  const complianceSelector = $("#compliance_select");

  brandSelector
    .select2({
      placeholder: "Select a brand",
    })
    .on("change", e => {
      updateProducts(e.target.value);
    });

  complianceSelector.select2({
    placeholder: "Select a compliance from products",
    allowClear: true,
  });

  if (brandSelector.val()) {
    updateProducts(brandSelector.val(), complianceSelector.data("selected-id"));
  }
});

function updateProducts(brandId, selectedId = null) {
  $.ajax({
    url: "/admin/compliances/filter_by_brand",
    type: "GET",
    data: { brand_id: brandId },
    success: data => {
      const productSelector = $("#compliance_select");
      productSelector.empty().trigger("change");
      productSelector
        .append(
          new Option("Select a compliance from products", "", false, false)
        )
        .trigger("change");

      data.forEach(product => {
        const isSelected = product.id === selectedId;
        const option = new Option(
          product.name + " – (GWX Product ID: " + product.gwx_product_id + ")",
          product.id,
          isSelected,
          isSelected
        );
        productSelector.append(option).trigger("change");
      });
      productSelector.select2(); // Re-initialize select2 to account for new options
    },
    error: (jqXHR, textStatus, errorThrown) => {
      console.error("Error loading products: ", textStatus, errorThrown);
    },
  });
}
