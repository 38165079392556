// Load Active Admin's styles into Webpacker,
// see `active_admin.scss` for customization.

require("turbolinks").start();
import "../stylesheets/active_admin";
import "trix";
import "@rails/actiontext";
import "@activeadmin/activeadmin";
import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();

import "../src/select2";
import "../src/directUpload";
import "../src/adminSyncRefresh";
import "../src/mediaSearch";
import "../src/complianceSearch";
import "../src/complianceRequirementSearch";
import "../src/trixConfig";
import "../src/progressPaymentsCheckbox";
import "../src/videoChapters";
import "chartkick/chart.js";
