$(document).on("turbolinks:load", function () {
  const brandSelector = $("#compliance_requirement_brand_select");
  const productSelector = $("#compliance_requirement_product_select");
  const geozoneSelector = $("#compliance_requirement_geozone_select");

  brandSelector
    .select2({
      placeholder: "Select a brand",
    })
    .on("change", e => {
      updateProductsAndGeozones(e.target.value);
    });

  productSelector.select2({
    placeholder: "Select a product",
    allowClear: true,
  });

  geozoneSelector.select2({
    placeholder: "Select a geozone",
    allowClear: true,
  });

  if (brandSelector.val()) {
    updateProductsAndGeozones(brandSelector.val(), {
      selectedProductId: productSelector.data("selected-product-id"),
      selectedGeozoneId: geozoneSelector.data("selected-geozone-id"),
    });
  }

  function updateProductsAndGeozones(
    brandId,
    { selectedProductId = null, selectedGeozoneId = null } = {}
  ) {
    $.ajax({
      url: `/admin/compliance_requirements/filter_by_brand`,
      type: "GET",
      data: { brand_id: brandId },
      success: data => {
        const productSelector = $("#compliance_requirement_product_select");
        productSelector.empty().trigger("change");
        productSelector
          .append(new Option("Select a product", "", false, false))
          .trigger("change");

        data.products.forEach(product => {
          const isSelected = product.id === selectedProductId;
          const option = new Option(
            product.name +
              " – (GWX Product ID: " +
              product.gwx_product_id +
              ")",
            product.id,
            isSelected,
            isSelected
          );
          productSelector.append(option).trigger("change");
        });
        productSelector.select2(); // Re-initialize select2 to account for new options

        const geozoneSelector = $("#compliance_requirement_geozone_select");
        geozoneSelector.empty().trigger("change");
        geozoneSelector
          .append(new Option("Select a geozone", "", false, false))
          .trigger("change");

        data.geozones.forEach(geozone => {
          const isSelected = geozone.id === selectedGeozoneId;
          const option = new Option(
            geozone.name,
            geozone.id,
            isSelected,
            isSelected
          );
          geozoneSelector.append(option).trigger("change");
        });
        geozoneSelector.select2(); // Re-initialize select2 to account for new options
      },
      error: (jqXHR, textStatus, errorThrown) => {
        console.error("Error loading products: ", textStatus, errorThrown);
      },
    });
  }
});
